jQuery(document).ready(function($) {

    ScrollReveal().reveal('header .background-image', {
        // delay: 100,
        distance: '5%',
        origin: 'right',
        duration: 1500,
    });

    ScrollReveal().reveal('header h1 span', {
        delay: 500,
        distance: '150%',
        origin: 'left',
        interval: 100,
        duration: 1000,
    });

    ScrollReveal().reveal('main article', {
        viewFactor: 0.5,
        distance: '5%',
        origin: 'bottom',
    });

    // ScrollReveal().reveal('header .logo-placeholder', {
    //     delay: 1000,
    //     distance: '150%',
    //     origin: 'right',
    // });

    // SMOOTH SCROLL TO ANCHOR
    $('a[href^="#"]').not('.popup-link').on('click', function (e) {
        event.stopImmediatePropagation();
        e.preventDefault();

        var target = this.hash;
        var $target = $(target);
        if ($target.length) {
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 500, 'swing', function () {
                window.location.hash = target;
            });
        }

        $('.menu--main').removeClass('menu-open');
        $('body').removeClass('main-menu-active');
        $('#menu-toggle').removeClass('active');
        $('nav#block-hoofdnavigatie > ul.menu > li.menu-item--expanded').removeClass('menu-open');
    });

    $('.video .play-btn').magnificPopup({
        type:'iframe',
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('.popup-link').magnificPopup({
        type: 'inline',
        src: 'small-dialog',

		fixedContentPos: false,
		fixedBgPos: true,

		overflowY: 'auto',

		closeBtnInside: true,
		preloader: false,
		
		midClick: true,
		removalDelay: 300,
		mainClass: 'my-mfp-zoom-in'
	});

    if($(window).width() <= 667){
        $('#tips .container ul').slick({
            slidesToShow: 1,
            rows: 3,
            dots: true,
            arrows: false,
            autoplay: true,
        });
    }

    $('#menu-toggle').on('click touchstart', function(event){
        $('body').toggleClass('main-menu-active');
        $(this).toggleClass('active');
        event.stopImmediatePropagation();
        event.preventDefault();
      });

      



});